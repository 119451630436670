/*!
 * Bootstrap Grid v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
.bx-container,
.bx-container-fluid,
.bx-container-xxl,
.bx-container-xl,
.bx-container-lg,
.bx-container-md,
.bx-container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .bx-container-sm,
   .bx-container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .bx-container-md,
   .bx-container-sm,
   .bx-container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .bx-container-lg,
   .bx-container-md,
   .bx-container-sm,
   .bx-container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .bx-container-xl,
   .bx-container-lg,
   .bx-container-md,
   .bx-container-sm,
   .bx-container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .bx-container-xxl,
   .bx-container-xl,
   .bx-container-lg,
   .bx-container-md,
   .bx-container-sm,
   .bx-container {
    max-width: 1320px;
  }
}
.bx-row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
}
.bx-row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.bx-col {
  flex: 1 0 0%;
}

.bx-row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.bx-row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.bx-row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.bx-row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.bx-row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.bx-row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.bx-row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

@media (min-width: 576px) {
  .bx-col-sm {
    flex: 1 0 0%;
  }

  .bx-row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .bx-row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .bx-row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .bx-row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .bx-row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .bx-row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .bx-row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 768px) {
  .bx-col-md {
    flex: 1 0 0%;
  }

  .bx-row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .bx-row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .bx-row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .bx-row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .bx-row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .bx-row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .bx-row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 992px) {
  .bx-col-lg {
    flex: 1 0 0%;
  }

  .bx-row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .bx-row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .bx-row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .bx-row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .bx-row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .bx-row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .bx-row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 1200px) {
  .bx-col-xl {
    flex: 1 0 0%;
  }

  .bx-row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .bx-row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .bx-row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .bx-row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .bx-row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .bx-row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .bx-row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 1400px) {
  .bx-col-xxl {
    flex: 1 0 0%;
  }

  .bx-row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .bx-row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .bx-row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .bx-row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .bx-row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .bx-row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .bx-row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
.bx-col-auto {
  flex: 0 0 auto;
  width: auto;
}

.bx-col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.bx-col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.bx-col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.bx-col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.bx-col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.bx-col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.bx-col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.bx-col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.bx-col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.bx-col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.bx-col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.bx-col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.bx-offset-1 {
  margin-left: 8.33333333%;
}

.bx-offset-2 {
  margin-left: 16.66666667%;
}

.bx-offset-3 {
  margin-left: 25%;
}

.bx-offset-4 {
  margin-left: 33.33333333%;
}

.bx-offset-5 {
  margin-left: 41.66666667%;
}

.bx-offset-6 {
  margin-left: 50%;
}

.bx-offset-7 {
  margin-left: 58.33333333%;
}

.bx-offset-8 {
  margin-left: 66.66666667%;
}

.bx-offset-9 {
  margin-left: 75%;
}

.bx-offset-10 {
  margin-left: 83.33333333%;
}

.bx-offset-11 {
  margin-left: 91.66666667%;
}

.bx-g-0,
.bx-gx-0 {
  --bs-gutter-x: 0;
}

.bx-g-0,
.bx-gy-0 {
  --bs-gutter-y: 0;
}

.bx-g-1,
.bx-gx-1 {
  --bs-gutter-x: 0.25rem;
}

.bx-g-1,
.bx-gy-1 {
  --bs-gutter-y: 0.25rem;
}

.bx-g-2,
.bx-gx-2 {
  --bs-gutter-x: 0.5rem;
}

.bx-g-2,
.bx-gy-2 {
  --bs-gutter-y: 0.5rem;
}

.bx-g-3,
.bx-gx-3 {
  --bs-gutter-x: 1rem;
}

.bx-g-3,
.bx-gy-3 {
  --bs-gutter-y: 1rem;
}

.bx-g-4,
.bx-gx-4 {
  --bs-gutter-x: 1.5rem;
}

.bx-g-4,
.bx-gy-4 {
  --bs-gutter-y: 1.5rem;
}

.bx-g-5,
.bx-gx-5 {
  --bs-gutter-x: 3rem;
}

.bx-g-5,
.bx-gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .bx-col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .bx-col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .bx-col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .bx-col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .bx-col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .bx-col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .bx-col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .bx-col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .bx-col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .bx-col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .bx-col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .bx-col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .bx-col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .bx-offset-sm-0 {
    margin-left: 0;
  }

  .bx-offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .bx-offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .bx-offset-sm-3 {
    margin-left: 25%;
  }

  .bx-offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .bx-offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .bx-offset-sm-6 {
    margin-left: 50%;
  }

  .bx-offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .bx-offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .bx-offset-sm-9 {
    margin-left: 75%;
  }

  .bx-offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .bx-offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .bx-g-sm-0,
.bx-gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .bx-g-sm-0,
.bx-gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .bx-g-sm-1,
.bx-gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .bx-g-sm-1,
.bx-gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .bx-g-sm-2,
.bx-gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .bx-g-sm-2,
.bx-gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .bx-g-sm-3,
.bx-gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .bx-g-sm-3,
.bx-gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .bx-g-sm-4,
.bx-gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .bx-g-sm-4,
.bx-gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .bx-g-sm-5,
.bx-gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .bx-g-sm-5,
.bx-gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .bx-col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .bx-col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .bx-col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .bx-col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .bx-col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .bx-col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .bx-col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .bx-col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .bx-col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .bx-col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .bx-col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .bx-col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .bx-col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .bx-offset-md-0 {
    margin-left: 0;
  }

  .bx-offset-md-1 {
    margin-left: 8.33333333%;
  }

  .bx-offset-md-2 {
    margin-left: 16.66666667%;
  }

  .bx-offset-md-3 {
    margin-left: 25%;
  }

  .bx-offset-md-4 {
    margin-left: 33.33333333%;
  }

  .bx-offset-md-5 {
    margin-left: 41.66666667%;
  }

  .bx-offset-md-6 {
    margin-left: 50%;
  }

  .bx-offset-md-7 {
    margin-left: 58.33333333%;
  }

  .bx-offset-md-8 {
    margin-left: 66.66666667%;
  }

  .bx-offset-md-9 {
    margin-left: 75%;
  }

  .bx-offset-md-10 {
    margin-left: 83.33333333%;
  }

  .bx-offset-md-11 {
    margin-left: 91.66666667%;
  }

  .bx-g-md-0,
.bx-gx-md-0 {
    --bs-gutter-x: 0;
  }

  .bx-g-md-0,
.bx-gy-md-0 {
    --bs-gutter-y: 0;
  }

  .bx-g-md-1,
.bx-gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .bx-g-md-1,
.bx-gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .bx-g-md-2,
.bx-gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .bx-g-md-2,
.bx-gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .bx-g-md-3,
.bx-gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .bx-g-md-3,
.bx-gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .bx-g-md-4,
.bx-gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .bx-g-md-4,
.bx-gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .bx-g-md-5,
.bx-gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .bx-g-md-5,
.bx-gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .bx-col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .bx-col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .bx-col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .bx-col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .bx-col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .bx-col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .bx-col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .bx-col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .bx-col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .bx-col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .bx-col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .bx-col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .bx-col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .bx-offset-lg-0 {
    margin-left: 0;
  }

  .bx-offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .bx-offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .bx-offset-lg-3 {
    margin-left: 25%;
  }

  .bx-offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .bx-offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .bx-offset-lg-6 {
    margin-left: 50%;
  }

  .bx-offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .bx-offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .bx-offset-lg-9 {
    margin-left: 75%;
  }

  .bx-offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .bx-offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .bx-g-lg-0,
.bx-gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .bx-g-lg-0,
.bx-gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .bx-g-lg-1,
.bx-gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .bx-g-lg-1,
.bx-gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .bx-g-lg-2,
.bx-gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .bx-g-lg-2,
.bx-gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .bx-g-lg-3,
.bx-gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .bx-g-lg-3,
.bx-gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .bx-g-lg-4,
.bx-gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .bx-g-lg-4,
.bx-gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .bx-g-lg-5,
.bx-gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .bx-g-lg-5,
.bx-gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .bx-col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .bx-col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .bx-col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .bx-col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .bx-col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .bx-col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .bx-col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .bx-col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .bx-col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .bx-col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .bx-col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .bx-col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .bx-col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .bx-offset-xl-0 {
    margin-left: 0;
  }

  .bx-offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .bx-offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .bx-offset-xl-3 {
    margin-left: 25%;
  }

  .bx-offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .bx-offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .bx-offset-xl-6 {
    margin-left: 50%;
  }

  .bx-offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .bx-offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .bx-offset-xl-9 {
    margin-left: 75%;
  }

  .bx-offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .bx-offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .bx-g-xl-0,
.bx-gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .bx-g-xl-0,
.bx-gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .bx-g-xl-1,
.bx-gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .bx-g-xl-1,
.bx-gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .bx-g-xl-2,
.bx-gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .bx-g-xl-2,
.bx-gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .bx-g-xl-3,
.bx-gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .bx-g-xl-3,
.bx-gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .bx-g-xl-4,
.bx-gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .bx-g-xl-4,
.bx-gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .bx-g-xl-5,
.bx-gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .bx-g-xl-5,
.bx-gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .bx-col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .bx-col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .bx-col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .bx-col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .bx-col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .bx-col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .bx-col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .bx-col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .bx-col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .bx-col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .bx-col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .bx-col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .bx-col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .bx-offset-xxl-0 {
    margin-left: 0;
  }

  .bx-offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .bx-offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .bx-offset-xxl-3 {
    margin-left: 25%;
  }

  .bx-offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .bx-offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .bx-offset-xxl-6 {
    margin-left: 50%;
  }

  .bx-offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .bx-offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .bx-offset-xxl-9 {
    margin-left: 75%;
  }

  .bx-offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .bx-offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .bx-g-xxl-0,
.bx-gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .bx-g-xxl-0,
.bx-gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .bx-g-xxl-1,
.bx-gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .bx-g-xxl-1,
.bx-gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .bx-g-xxl-2,
.bx-gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .bx-g-xxl-2,
.bx-gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .bx-g-xxl-3,
.bx-gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .bx-g-xxl-3,
.bx-gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .bx-g-xxl-4,
.bx-gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .bx-g-xxl-4,
.bx-gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .bx-g-xxl-5,
.bx-gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .bx-g-xxl-5,
.bx-gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.bx-d-inline {
  display: inline !important;
}

.bx-d-inline-block {
  display: inline-block !important;
}

.bx-d-block {
  display: block !important;
}

.bx-d-grid {
  display: grid !important;
}

.bx-d-table {
  display: table !important;
}

.bx-d-table-row {
  display: table-row !important;
}

.bx-d-table-cell {
  display: table-cell !important;
}

.bx-d-flex {
  display: flex !important;
}

.bx-d-inline-flex {
  display: inline-flex !important;
}

.bx-d-none {
  display: none !important;
}

.bx-flex-fill {
  flex: 1 1 auto !important;
}

.bx-flex-row {
  flex-direction: row !important;
}

.bx-flex-column {
  flex-direction: column !important;
}

.bx-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.bx-flex-column-reverse {
  flex-direction: column-reverse !important;
}

.bx-flex-grow-0 {
  flex-grow: 0 !important;
}

.bx-flex-grow-1 {
  flex-grow: 1 !important;
}

.bx-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.bx-flex-shrink-1 {
  flex-shrink: 1 !important;
}

.bx-flex-wrap {
  flex-wrap: wrap !important;
}

.bx-flex-nowrap {
  flex-wrap: nowrap !important;
}

.bx-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.bx-justify-content-start {
  justify-content: flex-start !important;
}

.bx-justify-content-end {
  justify-content: flex-end !important;
}

.bx-justify-content-center {
  justify-content: center !important;
}

.bx-justify-content-between {
  justify-content: space-between !important;
}

.bx-justify-content-around {
  justify-content: space-around !important;
}

.bx-justify-content-evenly {
  justify-content: space-evenly !important;
}

.bx-align-items-start {
  align-items: flex-start !important;
}

.bx-align-items-end {
  align-items: flex-end !important;
}

.bx-align-items-center {
  align-items: center !important;
}

.bx-align-items-baseline {
  align-items: baseline !important;
}

.bx-align-items-stretch {
  align-items: stretch !important;
}

.bx-align-content-start {
  align-content: flex-start !important;
}

.bx-align-content-end {
  align-content: flex-end !important;
}

.bx-align-content-center {
  align-content: center !important;
}

.bx-align-content-between {
  align-content: space-between !important;
}

.bx-align-content-around {
  align-content: space-around !important;
}

.bx-align-content-stretch {
  align-content: stretch !important;
}

.bx-align-self-auto {
  align-self: auto !important;
}

.bx-align-self-start {
  align-self: flex-start !important;
}

.bx-align-self-end {
  align-self: flex-end !important;
}

.bx-align-self-center {
  align-self: center !important;
}

.bx-align-self-baseline {
  align-self: baseline !important;
}

.bx-align-self-stretch {
  align-self: stretch !important;
}

.bx-order-first {
  order: -1 !important;
}

.bx-order-0 {
  order: 0 !important;
}

.bx-order-1 {
  order: 1 !important;
}

.bx-order-2 {
  order: 2 !important;
}

.bx-order-3 {
  order: 3 !important;
}

.bx-order-4 {
  order: 4 !important;
}

.bx-order-5 {
  order: 5 !important;
}

.bx-order-last {
  order: 6 !important;
}

.bx-m-0 {
  margin: 0 !important;
}

.bx-m-1 {
  margin: 0.25rem !important;
}

.bx-m-2 {
  margin: 0.5rem !important;
}

.bx-m-3 {
  margin: 1rem !important;
}

.bx-m-4 {
  margin: 1.5rem !important;
}

.bx-m-5 {
  margin: 3rem !important;
}

.bx-m-auto {
  margin: auto !important;
}

.bx-mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.bx-mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.bx-mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.bx-mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.bx-mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.bx-mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.bx-mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.bx-my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.bx-my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.bx-my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.bx-my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.bx-my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.bx-my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.bx-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.bx-mt-0 {
  margin-top: 0 !important;
}

.bx-mt-1 {
  margin-top: 0.25rem !important;
}

.bx-mt-2 {
  margin-top: 0.5rem !important;
}

.bx-mt-3 {
  margin-top: 1rem !important;
}

.bx-mt-4 {
  margin-top: 1.5rem !important;
}

.bx-mt-5 {
  margin-top: 3rem !important;
}

.bx-mt-auto {
  margin-top: auto !important;
}

.bx-me-0 {
  margin-right: 0 !important;
}

.bx-me-1 {
  margin-right: 0.25rem !important;
}

.bx-me-2 {
  margin-right: 0.5rem !important;
}

.bx-me-3 {
  margin-right: 1rem !important;
}

.bx-me-4 {
  margin-right: 1.5rem !important;
}

.bx-me-5 {
  margin-right: 3rem !important;
}

.bx-me-auto {
  margin-right: auto !important;
}

.bx-mb-0 {
  margin-bottom: 0 !important;
}

.bx-mb-1 {
  margin-bottom: 0.25rem !important;
}

.bx-mb-2 {
  margin-bottom: 0.5rem !important;
}

.bx-mb-3 {
  margin-bottom: 1rem !important;
}

.bx-mb-4 {
  margin-bottom: 1.5rem !important;
}

.bx-mb-5 {
  margin-bottom: 3rem !important;
}

.bx-mb-auto {
  margin-bottom: auto !important;
}

.bx-ms-0 {
  margin-left: 0 !important;
}

.bx-ms-1 {
  margin-left: 0.25rem !important;
}

.bx-ms-2 {
  margin-left: 0.5rem !important;
}

.bx-ms-3 {
  margin-left: 1rem !important;
}

.bx-ms-4 {
  margin-left: 1.5rem !important;
}

.bx-ms-5 {
  margin-left: 3rem !important;
}

.bx-ms-auto {
  margin-left: auto !important;
}

.bx-p-0 {
  padding: 0 !important;
}

.bx-p-1 {
  padding: 0.25rem !important;
}

.bx-p-2 {
  padding: 0.5rem !important;
}

.bx-p-3 {
  padding: 1rem !important;
}

.bx-p-4 {
  padding: 1.5rem !important;
}

.bx-p-5 {
  padding: 3rem !important;
}

.bx-px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.bx-px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.bx-px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.bx-px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.bx-px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.bx-px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.bx-py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.bx-py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.bx-py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.bx-py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.bx-py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.bx-py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.bx-pt-0 {
  padding-top: 0 !important;
}

.bx-pt-1 {
  padding-top: 0.25rem !important;
}

.bx-pt-2 {
  padding-top: 0.5rem !important;
}

.bx-pt-3 {
  padding-top: 1rem !important;
}

.bx-pt-4 {
  padding-top: 1.5rem !important;
}

.bx-pt-5 {
  padding-top: 3rem !important;
}

.bx-pe-0 {
  padding-right: 0 !important;
}

.bx-pe-1 {
  padding-right: 0.25rem !important;
}

.bx-pe-2 {
  padding-right: 0.5rem !important;
}

.bx-pe-3 {
  padding-right: 1rem !important;
}

.bx-pe-4 {
  padding-right: 1.5rem !important;
}

.bx-pe-5 {
  padding-right: 3rem !important;
}

.bx-pb-0 {
  padding-bottom: 0 !important;
}

.bx-pb-1 {
  padding-bottom: 0.25rem !important;
}

.bx-pb-2 {
  padding-bottom: 0.5rem !important;
}

.bx-pb-3 {
  padding-bottom: 1rem !important;
}

.bx-pb-4 {
  padding-bottom: 1.5rem !important;
}

.bx-pb-5 {
  padding-bottom: 3rem !important;
}

.bx-ps-0 {
  padding-left: 0 !important;
}

.bx-ps-1 {
  padding-left: 0.25rem !important;
}

.bx-ps-2 {
  padding-left: 0.5rem !important;
}

.bx-ps-3 {
  padding-left: 1rem !important;
}

.bx-ps-4 {
  padding-left: 1.5rem !important;
}

.bx-ps-5 {
  padding-left: 3rem !important;
}

@media (min-width: 576px) {
  .bx-d-sm-inline {
    display: inline !important;
  }

  .bx-d-sm-inline-block {
    display: inline-block !important;
  }

  .bx-d-sm-block {
    display: block !important;
  }

  .bx-d-sm-grid {
    display: grid !important;
  }

  .bx-d-sm-table {
    display: table !important;
  }

  .bx-d-sm-table-row {
    display: table-row !important;
  }

  .bx-d-sm-table-cell {
    display: table-cell !important;
  }

  .bx-d-sm-flex {
    display: flex !important;
  }

  .bx-d-sm-inline-flex {
    display: inline-flex !important;
  }

  .bx-d-sm-none {
    display: none !important;
  }

  .bx-flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .bx-flex-sm-row {
    flex-direction: row !important;
  }

  .bx-flex-sm-column {
    flex-direction: column !important;
  }

  .bx-flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .bx-flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .bx-flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .bx-flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .bx-flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .bx-flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .bx-flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .bx-flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .bx-flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .bx-justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .bx-justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .bx-justify-content-sm-center {
    justify-content: center !important;
  }

  .bx-justify-content-sm-between {
    justify-content: space-between !important;
  }

  .bx-justify-content-sm-around {
    justify-content: space-around !important;
  }

  .bx-justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .bx-align-items-sm-start {
    align-items: flex-start !important;
  }

  .bx-align-items-sm-end {
    align-items: flex-end !important;
  }

  .bx-align-items-sm-center {
    align-items: center !important;
  }

  .bx-align-items-sm-baseline {
    align-items: baseline !important;
  }

  .bx-align-items-sm-stretch {
    align-items: stretch !important;
  }

  .bx-align-content-sm-start {
    align-content: flex-start !important;
  }

  .bx-align-content-sm-end {
    align-content: flex-end !important;
  }

  .bx-align-content-sm-center {
    align-content: center !important;
  }

  .bx-align-content-sm-between {
    align-content: space-between !important;
  }

  .bx-align-content-sm-around {
    align-content: space-around !important;
  }

  .bx-align-content-sm-stretch {
    align-content: stretch !important;
  }

  .bx-align-self-sm-auto {
    align-self: auto !important;
  }

  .bx-align-self-sm-start {
    align-self: flex-start !important;
  }

  .bx-align-self-sm-end {
    align-self: flex-end !important;
  }

  .bx-align-self-sm-center {
    align-self: center !important;
  }

  .bx-align-self-sm-baseline {
    align-self: baseline !important;
  }

  .bx-align-self-sm-stretch {
    align-self: stretch !important;
  }

  .bx-order-sm-first {
    order: -1 !important;
  }

  .bx-order-sm-0 {
    order: 0 !important;
  }

  .bx-order-sm-1 {
    order: 1 !important;
  }

  .bx-order-sm-2 {
    order: 2 !important;
  }

  .bx-order-sm-3 {
    order: 3 !important;
  }

  .bx-order-sm-4 {
    order: 4 !important;
  }

  .bx-order-sm-5 {
    order: 5 !important;
  }

  .bx-order-sm-last {
    order: 6 !important;
  }

  .bx-m-sm-0 {
    margin: 0 !important;
  }

  .bx-m-sm-1 {
    margin: 0.25rem !important;
  }

  .bx-m-sm-2 {
    margin: 0.5rem !important;
  }

  .bx-m-sm-3 {
    margin: 1rem !important;
  }

  .bx-m-sm-4 {
    margin: 1.5rem !important;
  }

  .bx-m-sm-5 {
    margin: 3rem !important;
  }

  .bx-m-sm-auto {
    margin: auto !important;
  }

  .bx-mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .bx-mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .bx-mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .bx-mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .bx-mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .bx-mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .bx-mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .bx-my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .bx-my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .bx-my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .bx-my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .bx-my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .bx-my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .bx-my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .bx-mt-sm-0 {
    margin-top: 0 !important;
  }

  .bx-mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .bx-mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .bx-mt-sm-3 {
    margin-top: 1rem !important;
  }

  .bx-mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .bx-mt-sm-5 {
    margin-top: 3rem !important;
  }

  .bx-mt-sm-auto {
    margin-top: auto !important;
  }

  .bx-me-sm-0 {
    margin-right: 0 !important;
  }

  .bx-me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .bx-me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .bx-me-sm-3 {
    margin-right: 1rem !important;
  }

  .bx-me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .bx-me-sm-5 {
    margin-right: 3rem !important;
  }

  .bx-me-sm-auto {
    margin-right: auto !important;
  }

  .bx-mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .bx-mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .bx-mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .bx-mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .bx-mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .bx-mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .bx-mb-sm-auto {
    margin-bottom: auto !important;
  }

  .bx-ms-sm-0 {
    margin-left: 0 !important;
  }

  .bx-ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .bx-ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .bx-ms-sm-3 {
    margin-left: 1rem !important;
  }

  .bx-ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .bx-ms-sm-5 {
    margin-left: 3rem !important;
  }

  .bx-ms-sm-auto {
    margin-left: auto !important;
  }

  .bx-p-sm-0 {
    padding: 0 !important;
  }

  .bx-p-sm-1 {
    padding: 0.25rem !important;
  }

  .bx-p-sm-2 {
    padding: 0.5rem !important;
  }

  .bx-p-sm-3 {
    padding: 1rem !important;
  }

  .bx-p-sm-4 {
    padding: 1.5rem !important;
  }

  .bx-p-sm-5 {
    padding: 3rem !important;
  }

  .bx-px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .bx-px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .bx-px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .bx-px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .bx-px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .bx-px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .bx-py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .bx-py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .bx-py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .bx-py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .bx-py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .bx-py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .bx-pt-sm-0 {
    padding-top: 0 !important;
  }

  .bx-pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .bx-pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .bx-pt-sm-3 {
    padding-top: 1rem !important;
  }

  .bx-pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .bx-pt-sm-5 {
    padding-top: 3rem !important;
  }

  .bx-pe-sm-0 {
    padding-right: 0 !important;
  }

  .bx-pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .bx-pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .bx-pe-sm-3 {
    padding-right: 1rem !important;
  }

  .bx-pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .bx-pe-sm-5 {
    padding-right: 3rem !important;
  }

  .bx-pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .bx-pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .bx-pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .bx-pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .bx-pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .bx-pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .bx-ps-sm-0 {
    padding-left: 0 !important;
  }

  .bx-ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .bx-ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .bx-ps-sm-3 {
    padding-left: 1rem !important;
  }

  .bx-ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .bx-ps-sm-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 768px) {
  .bx-d-md-inline {
    display: inline !important;
  }

  .bx-d-md-inline-block {
    display: inline-block !important;
  }

  .bx-d-md-block {
    display: block !important;
  }

  .bx-d-md-grid {
    display: grid !important;
  }

  .bx-d-md-table {
    display: table !important;
  }

  .bx-d-md-table-row {
    display: table-row !important;
  }

  .bx-d-md-table-cell {
    display: table-cell !important;
  }

  .bx-d-md-flex {
    display: flex !important;
  }

  .bx-d-md-inline-flex {
    display: inline-flex !important;
  }

  .bx-d-md-none {
    display: none !important;
  }

  .bx-flex-md-fill {
    flex: 1 1 auto !important;
  }

  .bx-flex-md-row {
    flex-direction: row !important;
  }

  .bx-flex-md-column {
    flex-direction: column !important;
  }

  .bx-flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .bx-flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .bx-flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .bx-flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .bx-flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .bx-flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .bx-flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .bx-flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .bx-flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .bx-justify-content-md-start {
    justify-content: flex-start !important;
  }

  .bx-justify-content-md-end {
    justify-content: flex-end !important;
  }

  .bx-justify-content-md-center {
    justify-content: center !important;
  }

  .bx-justify-content-md-between {
    justify-content: space-between !important;
  }

  .bx-justify-content-md-around {
    justify-content: space-around !important;
  }

  .bx-justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .bx-align-items-md-start {
    align-items: flex-start !important;
  }

  .bx-align-items-md-end {
    align-items: flex-end !important;
  }

  .bx-align-items-md-center {
    align-items: center !important;
  }

  .bx-align-items-md-baseline {
    align-items: baseline !important;
  }

  .bx-align-items-md-stretch {
    align-items: stretch !important;
  }

  .bx-align-content-md-start {
    align-content: flex-start !important;
  }

  .bx-align-content-md-end {
    align-content: flex-end !important;
  }

  .bx-align-content-md-center {
    align-content: center !important;
  }

  .bx-align-content-md-between {
    align-content: space-between !important;
  }

  .bx-align-content-md-around {
    align-content: space-around !important;
  }

  .bx-align-content-md-stretch {
    align-content: stretch !important;
  }

  .bx-align-self-md-auto {
    align-self: auto !important;
  }

  .bx-align-self-md-start {
    align-self: flex-start !important;
  }

  .bx-align-self-md-end {
    align-self: flex-end !important;
  }

  .bx-align-self-md-center {
    align-self: center !important;
  }

  .bx-align-self-md-baseline {
    align-self: baseline !important;
  }

  .bx-align-self-md-stretch {
    align-self: stretch !important;
  }

  .bx-order-md-first {
    order: -1 !important;
  }

  .bx-order-md-0 {
    order: 0 !important;
  }

  .bx-order-md-1 {
    order: 1 !important;
  }

  .bx-order-md-2 {
    order: 2 !important;
  }

  .bx-order-md-3 {
    order: 3 !important;
  }

  .bx-order-md-4 {
    order: 4 !important;
  }

  .bx-order-md-5 {
    order: 5 !important;
  }

  .bx-order-md-last {
    order: 6 !important;
  }

  .bx-m-md-0 {
    margin: 0 !important;
  }

  .bx-m-md-1 {
    margin: 0.25rem !important;
  }

  .bx-m-md-2 {
    margin: 0.5rem !important;
  }

  .bx-m-md-3 {
    margin: 1rem !important;
  }

  .bx-m-md-4 {
    margin: 1.5rem !important;
  }

  .bx-m-md-5 {
    margin: 3rem !important;
  }

  .bx-m-md-auto {
    margin: auto !important;
  }

  .bx-mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .bx-mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .bx-mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .bx-mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .bx-mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .bx-mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .bx-mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .bx-my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .bx-my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .bx-my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .bx-my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .bx-my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .bx-my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .bx-my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .bx-mt-md-0 {
    margin-top: 0 !important;
  }

  .bx-mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .bx-mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .bx-mt-md-3 {
    margin-top: 1rem !important;
  }

  .bx-mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .bx-mt-md-5 {
    margin-top: 3rem !important;
  }

  .bx-mt-md-auto {
    margin-top: auto !important;
  }

  .bx-me-md-0 {
    margin-right: 0 !important;
  }

  .bx-me-md-1 {
    margin-right: 0.25rem !important;
  }

  .bx-me-md-2 {
    margin-right: 0.5rem !important;
  }

  .bx-me-md-3 {
    margin-right: 1rem !important;
  }

  .bx-me-md-4 {
    margin-right: 1.5rem !important;
  }

  .bx-me-md-5 {
    margin-right: 3rem !important;
  }

  .bx-me-md-auto {
    margin-right: auto !important;
  }

  .bx-mb-md-0 {
    margin-bottom: 0 !important;
  }

  .bx-mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .bx-mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .bx-mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .bx-mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .bx-mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .bx-mb-md-auto {
    margin-bottom: auto !important;
  }

  .bx-ms-md-0 {
    margin-left: 0 !important;
  }

  .bx-ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .bx-ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .bx-ms-md-3 {
    margin-left: 1rem !important;
  }

  .bx-ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .bx-ms-md-5 {
    margin-left: 3rem !important;
  }

  .bx-ms-md-auto {
    margin-left: auto !important;
  }

  .bx-p-md-0 {
    padding: 0 !important;
  }

  .bx-p-md-1 {
    padding: 0.25rem !important;
  }

  .bx-p-md-2 {
    padding: 0.5rem !important;
  }

  .bx-p-md-3 {
    padding: 1rem !important;
  }

  .bx-p-md-4 {
    padding: 1.5rem !important;
  }

  .bx-p-md-5 {
    padding: 3rem !important;
  }

  .bx-px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .bx-px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .bx-px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .bx-px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .bx-px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .bx-px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .bx-py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .bx-py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .bx-py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .bx-py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .bx-py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .bx-py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .bx-pt-md-0 {
    padding-top: 0 !important;
  }

  .bx-pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .bx-pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .bx-pt-md-3 {
    padding-top: 1rem !important;
  }

  .bx-pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .bx-pt-md-5 {
    padding-top: 3rem !important;
  }

  .bx-pe-md-0 {
    padding-right: 0 !important;
  }

  .bx-pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .bx-pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .bx-pe-md-3 {
    padding-right: 1rem !important;
  }

  .bx-pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .bx-pe-md-5 {
    padding-right: 3rem !important;
  }

  .bx-pb-md-0 {
    padding-bottom: 0 !important;
  }

  .bx-pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .bx-pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .bx-pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .bx-pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .bx-pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .bx-ps-md-0 {
    padding-left: 0 !important;
  }

  .bx-ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .bx-ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .bx-ps-md-3 {
    padding-left: 1rem !important;
  }

  .bx-ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .bx-ps-md-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 992px) {
  .bx-d-lg-inline {
    display: inline !important;
  }

  .bx-d-lg-inline-block {
    display: inline-block !important;
  }

  .bx-d-lg-block {
    display: block !important;
  }

  .bx-d-lg-grid {
    display: grid !important;
  }

  .bx-d-lg-table {
    display: table !important;
  }

  .bx-d-lg-table-row {
    display: table-row !important;
  }

  .bx-d-lg-table-cell {
    display: table-cell !important;
  }

  .bx-d-lg-flex {
    display: flex !important;
  }

  .bx-d-lg-inline-flex {
    display: inline-flex !important;
  }

  .bx-d-lg-none {
    display: none !important;
  }

  .bx-flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .bx-flex-lg-row {
    flex-direction: row !important;
  }

  .bx-flex-lg-column {
    flex-direction: column !important;
  }

  .bx-flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .bx-flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .bx-flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .bx-flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .bx-flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .bx-flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .bx-flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .bx-flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .bx-flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .bx-justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .bx-justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .bx-justify-content-lg-center {
    justify-content: center !important;
  }

  .bx-justify-content-lg-between {
    justify-content: space-between !important;
  }

  .bx-justify-content-lg-around {
    justify-content: space-around !important;
  }

  .bx-justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .bx-align-items-lg-start {
    align-items: flex-start !important;
  }

  .bx-align-items-lg-end {
    align-items: flex-end !important;
  }

  .bx-align-items-lg-center {
    align-items: center !important;
  }

  .bx-align-items-lg-baseline {
    align-items: baseline !important;
  }

  .bx-align-items-lg-stretch {
    align-items: stretch !important;
  }

  .bx-align-content-lg-start {
    align-content: flex-start !important;
  }

  .bx-align-content-lg-end {
    align-content: flex-end !important;
  }

  .bx-align-content-lg-center {
    align-content: center !important;
  }

  .bx-align-content-lg-between {
    align-content: space-between !important;
  }

  .bx-align-content-lg-around {
    align-content: space-around !important;
  }

  .bx-align-content-lg-stretch {
    align-content: stretch !important;
  }

  .bx-align-self-lg-auto {
    align-self: auto !important;
  }

  .bx-align-self-lg-start {
    align-self: flex-start !important;
  }

  .bx-align-self-lg-end {
    align-self: flex-end !important;
  }

  .bx-align-self-lg-center {
    align-self: center !important;
  }

  .bx-align-self-lg-baseline {
    align-self: baseline !important;
  }

  .bx-align-self-lg-stretch {
    align-self: stretch !important;
  }

  .bx-order-lg-first {
    order: -1 !important;
  }

  .bx-order-lg-0 {
    order: 0 !important;
  }

  .bx-order-lg-1 {
    order: 1 !important;
  }

  .bx-order-lg-2 {
    order: 2 !important;
  }

  .bx-order-lg-3 {
    order: 3 !important;
  }

  .bx-order-lg-4 {
    order: 4 !important;
  }

  .bx-order-lg-5 {
    order: 5 !important;
  }

  .bx-order-lg-last {
    order: 6 !important;
  }

  .bx-m-lg-0 {
    margin: 0 !important;
  }

  .bx-m-lg-1 {
    margin: 0.25rem !important;
  }

  .bx-m-lg-2 {
    margin: 0.5rem !important;
  }

  .bx-m-lg-3 {
    margin: 1rem !important;
  }

  .bx-m-lg-4 {
    margin: 1.5rem !important;
  }

  .bx-m-lg-5 {
    margin: 3rem !important;
  }

  .bx-m-lg-auto {
    margin: auto !important;
  }

  .bx-mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .bx-mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .bx-mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .bx-mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .bx-mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .bx-mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .bx-mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .bx-my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .bx-my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .bx-my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .bx-my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .bx-my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .bx-my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .bx-my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .bx-mt-lg-0 {
    margin-top: 0 !important;
  }

  .bx-mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .bx-mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .bx-mt-lg-3 {
    margin-top: 1rem !important;
  }

  .bx-mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .bx-mt-lg-5 {
    margin-top: 3rem !important;
  }

  .bx-mt-lg-auto {
    margin-top: auto !important;
  }

  .bx-me-lg-0 {
    margin-right: 0 !important;
  }

  .bx-me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .bx-me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .bx-me-lg-3 {
    margin-right: 1rem !important;
  }

  .bx-me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .bx-me-lg-5 {
    margin-right: 3rem !important;
  }

  .bx-me-lg-auto {
    margin-right: auto !important;
  }

  .bx-mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .bx-mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .bx-mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .bx-mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .bx-mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .bx-mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .bx-mb-lg-auto {
    margin-bottom: auto !important;
  }

  .bx-ms-lg-0 {
    margin-left: 0 !important;
  }

  .bx-ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .bx-ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .bx-ms-lg-3 {
    margin-left: 1rem !important;
  }

  .bx-ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .bx-ms-lg-5 {
    margin-left: 3rem !important;
  }

  .bx-ms-lg-auto {
    margin-left: auto !important;
  }

  .bx-p-lg-0 {
    padding: 0 !important;
  }

  .bx-p-lg-1 {
    padding: 0.25rem !important;
  }

  .bx-p-lg-2 {
    padding: 0.5rem !important;
  }

  .bx-p-lg-3 {
    padding: 1rem !important;
  }

  .bx-p-lg-4 {
    padding: 1.5rem !important;
  }

  .bx-p-lg-5 {
    padding: 3rem !important;
  }

  .bx-px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .bx-px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .bx-px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .bx-px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .bx-px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .bx-px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .bx-py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .bx-py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .bx-py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .bx-py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .bx-py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .bx-py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .bx-pt-lg-0 {
    padding-top: 0 !important;
  }

  .bx-pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .bx-pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .bx-pt-lg-3 {
    padding-top: 1rem !important;
  }

  .bx-pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .bx-pt-lg-5 {
    padding-top: 3rem !important;
  }

  .bx-pe-lg-0 {
    padding-right: 0 !important;
  }

  .bx-pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .bx-pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .bx-pe-lg-3 {
    padding-right: 1rem !important;
  }

  .bx-pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .bx-pe-lg-5 {
    padding-right: 3rem !important;
  }

  .bx-pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .bx-pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .bx-pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .bx-pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .bx-pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .bx-pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .bx-ps-lg-0 {
    padding-left: 0 !important;
  }

  .bx-ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .bx-ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .bx-ps-lg-3 {
    padding-left: 1rem !important;
  }

  .bx-ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .bx-ps-lg-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .bx-d-xl-inline {
    display: inline !important;
  }

  .bx-d-xl-inline-block {
    display: inline-block !important;
  }

  .bx-d-xl-block {
    display: block !important;
  }

  .bx-d-xl-grid {
    display: grid !important;
  }

  .bx-d-xl-table {
    display: table !important;
  }

  .bx-d-xl-table-row {
    display: table-row !important;
  }

  .bx-d-xl-table-cell {
    display: table-cell !important;
  }

  .bx-d-xl-flex {
    display: flex !important;
  }

  .bx-d-xl-inline-flex {
    display: inline-flex !important;
  }

  .bx-d-xl-none {
    display: none !important;
  }

  .bx-flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .bx-flex-xl-row {
    flex-direction: row !important;
  }

  .bx-flex-xl-column {
    flex-direction: column !important;
  }

  .bx-flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .bx-flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .bx-flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .bx-flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .bx-flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .bx-flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .bx-flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .bx-flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .bx-flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .bx-justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .bx-justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .bx-justify-content-xl-center {
    justify-content: center !important;
  }

  .bx-justify-content-xl-between {
    justify-content: space-between !important;
  }

  .bx-justify-content-xl-around {
    justify-content: space-around !important;
  }

  .bx-justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .bx-align-items-xl-start {
    align-items: flex-start !important;
  }

  .bx-align-items-xl-end {
    align-items: flex-end !important;
  }

  .bx-align-items-xl-center {
    align-items: center !important;
  }

  .bx-align-items-xl-baseline {
    align-items: baseline !important;
  }

  .bx-align-items-xl-stretch {
    align-items: stretch !important;
  }

  .bx-align-content-xl-start {
    align-content: flex-start !important;
  }

  .bx-align-content-xl-end {
    align-content: flex-end !important;
  }

  .bx-align-content-xl-center {
    align-content: center !important;
  }

  .bx-align-content-xl-between {
    align-content: space-between !important;
  }

  .bx-align-content-xl-around {
    align-content: space-around !important;
  }

  .bx-align-content-xl-stretch {
    align-content: stretch !important;
  }

  .bx-align-self-xl-auto {
    align-self: auto !important;
  }

  .bx-align-self-xl-start {
    align-self: flex-start !important;
  }

  .bx-align-self-xl-end {
    align-self: flex-end !important;
  }

  .bx-align-self-xl-center {
    align-self: center !important;
  }

  .bx-align-self-xl-baseline {
    align-self: baseline !important;
  }

  .bx-align-self-xl-stretch {
    align-self: stretch !important;
  }

  .bx-order-xl-first {
    order: -1 !important;
  }

  .bx-order-xl-0 {
    order: 0 !important;
  }

  .bx-order-xl-1 {
    order: 1 !important;
  }

  .bx-order-xl-2 {
    order: 2 !important;
  }

  .bx-order-xl-3 {
    order: 3 !important;
  }

  .bx-order-xl-4 {
    order: 4 !important;
  }

  .bx-order-xl-5 {
    order: 5 !important;
  }

  .bx-order-xl-last {
    order: 6 !important;
  }

  .bx-m-xl-0 {
    margin: 0 !important;
  }

  .bx-m-xl-1 {
    margin: 0.25rem !important;
  }

  .bx-m-xl-2 {
    margin: 0.5rem !important;
  }

  .bx-m-xl-3 {
    margin: 1rem !important;
  }

  .bx-m-xl-4 {
    margin: 1.5rem !important;
  }

  .bx-m-xl-5 {
    margin: 3rem !important;
  }

  .bx-m-xl-auto {
    margin: auto !important;
  }

  .bx-mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .bx-mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .bx-mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .bx-mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .bx-mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .bx-mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .bx-mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .bx-my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .bx-my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .bx-my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .bx-my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .bx-my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .bx-my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .bx-my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .bx-mt-xl-0 {
    margin-top: 0 !important;
  }

  .bx-mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .bx-mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .bx-mt-xl-3 {
    margin-top: 1rem !important;
  }

  .bx-mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .bx-mt-xl-5 {
    margin-top: 3rem !important;
  }

  .bx-mt-xl-auto {
    margin-top: auto !important;
  }

  .bx-me-xl-0 {
    margin-right: 0 !important;
  }

  .bx-me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .bx-me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .bx-me-xl-3 {
    margin-right: 1rem !important;
  }

  .bx-me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .bx-me-xl-5 {
    margin-right: 3rem !important;
  }

  .bx-me-xl-auto {
    margin-right: auto !important;
  }

  .bx-mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .bx-mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .bx-mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .bx-mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .bx-mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .bx-mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .bx-mb-xl-auto {
    margin-bottom: auto !important;
  }

  .bx-ms-xl-0 {
    margin-left: 0 !important;
  }

  .bx-ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .bx-ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .bx-ms-xl-3 {
    margin-left: 1rem !important;
  }

  .bx-ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .bx-ms-xl-5 {
    margin-left: 3rem !important;
  }

  .bx-ms-xl-auto {
    margin-left: auto !important;
  }

  .bx-p-xl-0 {
    padding: 0 !important;
  }

  .bx-p-xl-1 {
    padding: 0.25rem !important;
  }

  .bx-p-xl-2 {
    padding: 0.5rem !important;
  }

  .bx-p-xl-3 {
    padding: 1rem !important;
  }

  .bx-p-xl-4 {
    padding: 1.5rem !important;
  }

  .bx-p-xl-5 {
    padding: 3rem !important;
  }

  .bx-px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .bx-px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .bx-px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .bx-px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .bx-px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .bx-px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .bx-py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .bx-py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .bx-py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .bx-py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .bx-py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .bx-py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .bx-pt-xl-0 {
    padding-top: 0 !important;
  }

  .bx-pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .bx-pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .bx-pt-xl-3 {
    padding-top: 1rem !important;
  }

  .bx-pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .bx-pt-xl-5 {
    padding-top: 3rem !important;
  }

  .bx-pe-xl-0 {
    padding-right: 0 !important;
  }

  .bx-pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .bx-pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .bx-pe-xl-3 {
    padding-right: 1rem !important;
  }

  .bx-pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .bx-pe-xl-5 {
    padding-right: 3rem !important;
  }

  .bx-pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .bx-pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .bx-pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .bx-pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .bx-pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .bx-pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .bx-ps-xl-0 {
    padding-left: 0 !important;
  }

  .bx-ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .bx-ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .bx-ps-xl-3 {
    padding-left: 1rem !important;
  }

  .bx-ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .bx-ps-xl-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .bx-d-xxl-inline {
    display: inline !important;
  }

  .bx-d-xxl-inline-block {
    display: inline-block !important;
  }

  .bx-d-xxl-block {
    display: block !important;
  }

  .bx-d-xxl-grid {
    display: grid !important;
  }

  .bx-d-xxl-table {
    display: table !important;
  }

  .bx-d-xxl-table-row {
    display: table-row !important;
  }

  .bx-d-xxl-table-cell {
    display: table-cell !important;
  }

  .bx-d-xxl-flex {
    display: flex !important;
  }

  .bx-d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .bx-d-xxl-none {
    display: none !important;
  }

  .bx-flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .bx-flex-xxl-row {
    flex-direction: row !important;
  }

  .bx-flex-xxl-column {
    flex-direction: column !important;
  }

  .bx-flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .bx-flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .bx-flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .bx-flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .bx-flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .bx-flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .bx-flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .bx-flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .bx-flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .bx-justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .bx-justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .bx-justify-content-xxl-center {
    justify-content: center !important;
  }

  .bx-justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .bx-justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .bx-justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .bx-align-items-xxl-start {
    align-items: flex-start !important;
  }

  .bx-align-items-xxl-end {
    align-items: flex-end !important;
  }

  .bx-align-items-xxl-center {
    align-items: center !important;
  }

  .bx-align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .bx-align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .bx-align-content-xxl-start {
    align-content: flex-start !important;
  }

  .bx-align-content-xxl-end {
    align-content: flex-end !important;
  }

  .bx-align-content-xxl-center {
    align-content: center !important;
  }

  .bx-align-content-xxl-between {
    align-content: space-between !important;
  }

  .bx-align-content-xxl-around {
    align-content: space-around !important;
  }

  .bx-align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .bx-align-self-xxl-auto {
    align-self: auto !important;
  }

  .bx-align-self-xxl-start {
    align-self: flex-start !important;
  }

  .bx-align-self-xxl-end {
    align-self: flex-end !important;
  }

  .bx-align-self-xxl-center {
    align-self: center !important;
  }

  .bx-align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .bx-align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .bx-order-xxl-first {
    order: -1 !important;
  }

  .bx-order-xxl-0 {
    order: 0 !important;
  }

  .bx-order-xxl-1 {
    order: 1 !important;
  }

  .bx-order-xxl-2 {
    order: 2 !important;
  }

  .bx-order-xxl-3 {
    order: 3 !important;
  }

  .bx-order-xxl-4 {
    order: 4 !important;
  }

  .bx-order-xxl-5 {
    order: 5 !important;
  }

  .bx-order-xxl-last {
    order: 6 !important;
  }

  .bx-m-xxl-0 {
    margin: 0 !important;
  }

  .bx-m-xxl-1 {
    margin: 0.25rem !important;
  }

  .bx-m-xxl-2 {
    margin: 0.5rem !important;
  }

  .bx-m-xxl-3 {
    margin: 1rem !important;
  }

  .bx-m-xxl-4 {
    margin: 1.5rem !important;
  }

  .bx-m-xxl-5 {
    margin: 3rem !important;
  }

  .bx-m-xxl-auto {
    margin: auto !important;
  }

  .bx-mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .bx-mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .bx-mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .bx-mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .bx-mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .bx-mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .bx-mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .bx-my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .bx-my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .bx-my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .bx-my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .bx-my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .bx-my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .bx-my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .bx-mt-xxl-0 {
    margin-top: 0 !important;
  }

  .bx-mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .bx-mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .bx-mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .bx-mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .bx-mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .bx-mt-xxl-auto {
    margin-top: auto !important;
  }

  .bx-me-xxl-0 {
    margin-right: 0 !important;
  }

  .bx-me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .bx-me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .bx-me-xxl-3 {
    margin-right: 1rem !important;
  }

  .bx-me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .bx-me-xxl-5 {
    margin-right: 3rem !important;
  }

  .bx-me-xxl-auto {
    margin-right: auto !important;
  }

  .bx-mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .bx-mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .bx-mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .bx-mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .bx-mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .bx-mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .bx-mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .bx-ms-xxl-0 {
    margin-left: 0 !important;
  }

  .bx-ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .bx-ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .bx-ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .bx-ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .bx-ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .bx-ms-xxl-auto {
    margin-left: auto !important;
  }

  .bx-p-xxl-0 {
    padding: 0 !important;
  }

  .bx-p-xxl-1 {
    padding: 0.25rem !important;
  }

  .bx-p-xxl-2 {
    padding: 0.5rem !important;
  }

  .bx-p-xxl-3 {
    padding: 1rem !important;
  }

  .bx-p-xxl-4 {
    padding: 1.5rem !important;
  }

  .bx-p-xxl-5 {
    padding: 3rem !important;
  }

  .bx-px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .bx-px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .bx-px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .bx-px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .bx-px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .bx-px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .bx-py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .bx-py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .bx-py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .bx-py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .bx-py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .bx-py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .bx-pt-xxl-0 {
    padding-top: 0 !important;
  }

  .bx-pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .bx-pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .bx-pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .bx-pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .bx-pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .bx-pe-xxl-0 {
    padding-right: 0 !important;
  }

  .bx-pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .bx-pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .bx-pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .bx-pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .bx-pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .bx-pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .bx-pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .bx-pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .bx-pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .bx-pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .bx-pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .bx-ps-xxl-0 {
    padding-left: 0 !important;
  }

  .bx-ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .bx-ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .bx-ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .bx-ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .bx-ps-xxl-5 {
    padding-left: 3rem !important;
  }
}
@media print {
  .bx-d-print-inline {
    display: inline !important;
  }

  .bx-d-print-inline-block {
    display: inline-block !important;
  }

  .bx-d-print-block {
    display: block !important;
  }

  .bx-d-print-grid {
    display: grid !important;
  }

  .bx-d-print-table {
    display: table !important;
  }

  .bx-d-print-table-row {
    display: table-row !important;
  }

  .bx-d-print-table-cell {
    display: table-cell !important;
  }

  .bx-d-print-flex {
    display: flex !important;
  }

  .bx-d-print-inline-flex {
    display: inline-flex !important;
  }

  .bx-d-print-none {
    display: none !important;
  }
}

/*# sourceMappingURL=bootstrap-grid.css.map */