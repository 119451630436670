.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.AppfloatSettings{
  position: fixed;
    top: 15px;
    right: 302px;
    z-index: 99999999;
}

/*.imageClass19x6{
  width:300px;
  height:300px;
  background-color: blue;
}*/

.customReactGalleryClass div{
  position:relative;
  padding-bottom: 70%;
}

.customReactGalleryClass img{
  /*position: absolute;*/
  top:0;
  left:0;
  width:100% !important;
  height:100%;
  object-fit: cover !important;
  object-position: center;
}

.customThumbnailReactGalleryClass span{
  padding-bottom:70%;
  position:relative;
}

.customThumbnailReactGalleryClass img{
  position: absolute;
  top:0;
  left:0;
  width:100% !important;
  height:100%;
  object-fit: cover !important;
  object-position: center;
}

.customSignInModalClass {
  border-radius: 30px !important;
  max-width: 400px;
}

.customSignInModalClass .content {
  border-radius: 30px !important;
}
.customMessageModalClass {
  border-radius: 30px !important;
  max-width: 470px;
}

.customMessageModalClass .content {
  border-radius: 30px !important;
}


.customSearchInputClass .ui.icon.input {
  width:100%;
}

.customSearchInputClass .results.transition.visible {
  max-height: 70vh;
  overflow-y: scroll;
}

.bot {
  bottom: 50px;
  right : 0px;
  position: fixed;
}

@media print {
  .noprints{
    display:none;
  }
}

.shadow-none{box-shadow:none !important;}
.ui.basic.teal.button.shadow-none{box-shadow:none !important;}
.ui.basic.teal.button.shadow-none:hover{box-shadow:none !important;}
.ui.basic.teal.button.dark-outline-teal{
  font-weight:bold;
  box-shadow: 0 0 0 3px #008081 inset!important;
  color: #008081!important;
  color:#008081 !important;
}
.ui.basic.teal.button.dark-outline-teal:hover{
  box-shadow: 0 0 0 3px #008081 inset!important;
  color: #008081!important;
  color:#008081 !important;
}
#root{
  display:flex;
  /*flex-wrap: wrap;*/
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height:100vh;
}

.sticky_footer{
  margin-top:auto !important;
}

.ui.modal>.close {
  top: 1.0535rem !important;
  right: 1rem !important;
  color: rgba(0,0,0,.87) !important;
}

.helper-input-semantic-ui {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  font-size: 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, .15);
  color: rgba(0, 0, 0, .87);
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  transition: color .1s ease, border-color .1s ease;
}